<script setup lang="ts">
import { PhCaretLeft, PhCaretRight } from '@phosphor-icons/vue';
import LazyImage from '../LazyImage.vue';
import { IconButton } from '@laam/ui/base';
const { $eventClient } = useNuxtApp();
interface NodesBar {
	feedNodes: {
		id: number;
		title: string;
		parent_id: number;
		logo: string;
		image: string;
		valhalla_score: number;
		product_count: number;
		node_level: number;
	}[];
}
defineProps<NodesBar>();
const scrollContainerRef = ref<HTMLElement | null>(null);
const showLeftIcon = ref(false);
const showRightIcon = ref(true);

const checkScrollPosition = () => {
	if (scrollContainerRef.value) {
		const { scrollLeft, scrollWidth, clientWidth } = scrollContainerRef.value;

		// Show left icon if we've scrolled at all
		showLeftIcon.value = scrollLeft > 0;

		// Show right icon if we haven't reached the end
		// Adding a small buffer (1px) to account for rounding errors
		showRightIcon.value = Math.abs(scrollWidth - clientWidth - scrollLeft) > 1;
	}
};

const handleScroll = (goRight: boolean) => {
	if (scrollContainerRef.value) {
		if (goRight) {
			scrollContainerRef.value.scrollBy({
				left: 200,
				behavior: 'smooth',
			});
		} else {
			scrollContainerRef.value.scrollBy({
				left: -200,
				behavior: 'smooth',
			});
		}
	}
};

const logEvent = (title: string, id: string) => {
	$eventClient.sendEvent('node-button-clicked', {
		title: title,
		id: id,
		url: window.location.href,
	});
};

// Watch for scroll events
onMounted(() => {
	scrollContainerRef.value?.addEventListener('scroll', checkScrollPosition);
	// Check initial scroll position
	checkScrollPosition();
});

onBeforeUnmount(() => {
	scrollContainerRef.value?.removeEventListener('scroll', checkScrollPosition);
});
</script>
<template>
	<div class="flex relative items-center justify-between">
		<IconButton
			v-if="showLeftIcon && !isMobileView()"
			rounded="full"
			variant="secondary"
			size="xs"
			class="top-[30px] absolute z-10 left-[-17px]"
			@click="handleScroll(false)"
		>
			<PhCaretLeft />
		</IconButton>
		<div
			ref="scrollContainerRef"
			class="w-full lg:px-none lg:pb-9xl relative px-3xl overflow-x-scroll no-scrollbar"
		>
			<div class="flex gap-md items-center justify-start w-full min-w-max">
				<div v-for="(item, idx) in feedNodes" :key="idx">
					<NuxtLink
						:to="`/nodes/${item.id}`"
						class="min-w-[100px] max-w-[120px] bg-gray-100 py-sm px-lg gap-xd rounded-medium h-full flex items-start lg:hidden text-xs font-medium text-gray-900"
						@click="logEvent(item.title, item.id.toString())"
					>
						<div class="flex gap-xs items-center truncate justify-between">
							<LazyImage
								:src="item.logo"
								:alt="item.title || ''"
								width="20"
								height="20"
								class="h-[20px] w-[20px]"
							/>
							{{ item.title }}
						</div>
					</NuxtLink>
					<NuxtLink
						:to="`/nodes/${item.id}`"
						class="hidden lg:block max-w-[120px]"
						@click="logEvent(item.title, item.id.toString())"
					>
						<div
							class="flex flex-col gap-md text-center w-[92px] h-[92px] text-xs font-medium text-gray-800"
						>
							<LazyImage
								:src="item.logo"
								:alt="item.title || ''"
								width="92"
								height="92"
								class="rounded-xl w-[92px] h-[92px]"
							>
							</LazyImage>
							{{ item.title }}
						</div>
					</NuxtLink>
				</div>
			</div>
		</div>
		<IconButton
			v-if="showRightIcon && !isMobileView()"
			rounded="full"
			variant="secondary"
			size="xs"
			class="top-[30px] absolute z-10 right-[-17px]"
			@click="handleScroll(true)"
		>
			<PhCaretRight />
		</IconButton>
	</div>
</template>
